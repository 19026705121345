import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import * as s from "./404.module.scss";

const cn = classNames.bind(s);

const NotFoundPageLayout: React.FunctionComponent = () => {
  return (
    <main className={s.page}>
      <h1 className={cn(s.heading)}>Page not found</h1>
      <p className={cn(s.paragraph)}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in{" "}
            <code className={cn(s.code)}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  );
};

export default NotFoundPageLayout;
